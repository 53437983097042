<template>
  <v-tabs class="homepage-settings" left vertical>

    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">SEO</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune text</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Date de contact</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiunea formular</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Program de lucru</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Detalii companie</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Premii si certificari</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Harta</span>
    </v-tab>


    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a   @click=" NavigationLink(`https://` + franchisee.domain + `/contact`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <seo-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/contact`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <text-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/contact`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <contact-details />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/contact`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <contact-form-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/contact`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <working-hours-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/contact`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <company-details-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/contact`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <certifications-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/contact`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <map-section />
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import TextSection from './components/TextSection'
import MapSection from './components/MapSection'
import SeoSection from './components/SeoSection'
import CertificationsSection from './components/CertificationsSection'
import CompanyDetailsSection from './components/CompanyDetailsSection'
import ContactDetails from './components/ContactDetails'
import WorkingHoursSection from './components/WorkingHoursSection'
import ContactFormSection from './components/ContactFormSection'

export default {
  name: 'index',
  components: {
    TextSection,
    MapSection,
    SeoSection,
    CertificationsSection,
    CompanyDetailsSection,
    ContactFormSection,
    WorkingHoursSection,
    ContactDetails
  },
  computed: {
    franchisee_id() {
      return this.$store.getters['auth/currentUser'].franchisee_id
    },
    franchisee() {
      return this.$store.getters['singleFranchisee/item']
    }
  },
  methods: {
    NavigationLink(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    loadFranchisee() {
      this.franchisee_id && this.$store.dispatch('singleFranchisee/load', this.franchisee_id)
    }
  },
  created() {
    this.loadFranchisee()
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.homepage-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>

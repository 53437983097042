<template>
  <cit-settings-manager action-url="settings/page-contact/working-hours-section">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.subtitle" dense hide-details label="Subtitlu" outlined/>
        <error-messages :errors="errors.subtitle"/>
      </div>

      <div class="mb-4">
        Program:
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.general_m_f" dense hide-details label="Luni - vineri" outlined/>
        <error-messages :errors="errors.general_m_f"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.general_sa" dense hide-details label="Sambata" outlined/>
        <error-messages :errors="errors.general_sa"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.general_su" dense hide-details label="Duminica" outlined/>
        <error-messages :errors="errors.general_su"/>
      </div>

      <div class="mb-4">
        Program Magazin:
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.shop_m_f" dense hide-details label="Luni - vineri" outlined/>
        <error-messages :errors="errors.shop_m_f"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.shop_sa" dense hide-details label="Sambata" outlined/>
        <error-messages :errors="errors.shop_sa"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.shop_su" dense hide-details label="Duminica" outlined/>
        <error-messages :errors="errors.shop_su"/>
      </div>

      <div class="mb-4">
        Program Customer Service:
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.service_m_f" dense hide-details label="Luni - vineri" outlined/>
        <error-messages :errors="errors.service_m_f"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.service_sa" dense hide-details label="Sambata" outlined/>
        <error-messages :errors="errors.service_sa"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.service_su" dense hide-details label="Duminica" outlined/>
        <error-messages :errors="errors.service_su"/>
      </div>


    </template>

  </cit-settings-manager>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import CitSettingsManager from '@/components/layout/CitSettings/CitSettingsManager'

export default {
  components: {
    CitSettingsManager,
    ErrorMessages
  }
}
</script>

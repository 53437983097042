<template>
  <cit-settings-manager action-url="settings/page-contact/contact-form-section">

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>
      </div>
      <div class="mb-4">
        <v-text-field v-model="form.subtitle" dense hide-details label="Subtitlu" outlined/>
        <error-messages :errors="errors.subtitle"/>
      </div>
      <div class="mb-4">
        <label>Continut</label>
        <wysiwyg v-model="form.body"/>
        <error-messages :errors="errors.body"/>
      </div>
    </template>

  </cit-settings-manager>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import CitSettingsManager from '@/components/layout/CitSettings/CitSettingsManager'
import Wysiwyg from '@/components/general-form/Wysiwyg'

export default {
  components: {
    Wysiwyg,
    CitSettingsManager,
    ErrorMessages
  }
}
</script>

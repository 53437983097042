<template>
  <v-sheet>
    <v-card>
      <v-card-text>
        <div class="mb-4">
          <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
          <error-messages :errors="errors.title"/>
        </div>
        <div class="mb-4">
          <v-textarea v-model="form.description" dense hide-details label="Descriere" outlined/>
          <error-messages :errors="errors.description"/>
        </div>
        <div class="mb-4">
          <v-switch
            v-model="form.show_contact_details"
            dense hide-details label="Afiseaza Datele de contact"
          ></v-switch>
          <error-messages :errors="errors.show_register_buttons"/>
        </div>
        <div class="mb-4">
          <v-switch
            v-model="form.show_register_buttons"
            dense hide-details label="Afiseaza butoanele de inregistrare"
          ></v-switch>
          <error-messages :errors="errors.show_register_buttons"/>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="loading" :loading="loading" color="primary" class="primary-button" @click="saveSettings">Salvare</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages
  },
  data () {
    return {
      loading: false,
      errors: {},
      form: {
        title: '',
        description: '',
        show_register_buttons: false,
        show_contact_details: false
      }
    }
  },
  methods: {
    getForm () {
      this.$http.get('settings/page-contact/text-section')
        .then(({data}) => {
          this.form = data
        })
    },
    saveSettings () {
      this.errors = {}
      this.loading = true
      this.$http.post('settings/page-contact/text-section', this.form)
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})

        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, {root: true})
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, {root: true})
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getForm()
  }
}
</script>

<template>
  <v-sheet class="py-4 my-4">
    <draggable
      :disabled="!enabled"
      :list="contactDetails"
      class="list-group cursor-move"
      ghost-class="ghost"
      @end="dragging = false"
      @start="stopDragging"
    >
      <div v-for="(item, index) in contactDetails" :key="index" class="mb-4 px-4">

        <div class="d-flex items-center mt-4">
          <span class="mr-3 w-2/12">Denumire</span>
          <v-text-field
            v-model="item.name"
            class="mt-0"
            dense
            hide-details
            label="Denumire"
            outlined
          ></v-text-field>
        </div>
        <error-messages :errors="errors[`details.${index}.name`]" class="mt-1"></error-messages>


        <div class="d-flex items-center mt-4">
          <span class="mr-3 w-2/12">Telefon</span>
          <v-text-field
            v-model="item.phone"
            class="mt-0"
            dense
            hide-details
            label="Telefon"
            outlined
          ></v-text-field>
        </div>
        <error-messages :errors="errors[`details.${index}.phone`]" class="mt-1"></error-messages>


        <div class="d-flex items-center mt-4">
          <span class="mr-3 w-2/12">E-Mail</span>
          <v-text-field
            v-model="item.mail"
            class="mt-0"
            dense
            hide-details
            label="E-Mail"
            outlined
          ></v-text-field>
        </div>
        <error-messages :errors="errors[`details.${index}.mail`]" class="mt-1"></error-messages>

        <v-divider class="mt-4"/>
      </div>

    </draggable>
    <v-row>
      <v-col class="pt-5 justify-end">
        <v-btn :loading="loading" class="mr-3"
               depressed
               large
               @click="addItem">
          Adauga sectiune noua
        </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          dark
          large
          @click="saveSettings"
        >
          Salveaza
        </v-btn>
      </v-col>
    </v-row>

  </v-sheet>
</template>

<script>
import draggable from 'vuedraggable'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  components: {
    ErrorMessages,
    draggable
  },
  data () {
    return {
      enabled: true,
      dragging: false,
      loading: false,
      contactDetails: [
        {
          name: '',
          phone: '',
          mail: ''
        }
      ],
      errors: {}
    }
  },
  methods: {
    stopDragging () {
      this.errors = {}
      this.dragging = false
    },
    addItem () {
      this.errors = {}
      this.contactDetails.push({
        name: '',
        phone: '',
        mail: ''
      })
    },
    loadSettings () {
      this.$http.get('settings/page-contact/details')
        .then(({data}) => {
          this.contactDetails = data.details
        })
    },
    saveSettings () {
      this.errors = {}
      this.loading = true
      this.$http.post('settings/page-contact/details', {
        details: this.contactDetails
      })
        .then(() => {
          this.$store.dispatch('notify', {
            title: 'Succes!',
            text: 'Setarile au fost salvate.',
            color: 'success'
          }, {root: true})

        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            }, {root: true})
          } else {
            this.$store.dispatch('notify', {
              title: 'Eroare!',
              text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            }, {root: true})
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.loadSettings()
  }
}
</script>

<template>
  <cit-settings-manager action-url="settings/page-contact/map-section">

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.address" dense hide-details label="Adresa" outlined/>
        <error-messages :errors="errors.address"/>
      </div>

      <div class="mb-4">
        <v-text-field v-model="form.address_url" dense hint="ex. https://www.google.com/maps/place/CIT+Grup/..." label="Link de la google maps"
                      outlined persistent-hint/>
        <error-messages :errors="errors.address_url"/>
      </div>

    </template>

  </cit-settings-manager>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import CitSettingsManager from '@/components/layout/CitSettings/CitSettingsManager'
import Wysiwyg from '@/components/general-form/Wysiwyg'

export default {
  components: {
    Wysiwyg,
    CitSettingsManager,
    ErrorMessages
  }
}
</script>
